
import { Component, Vue, Prop } from "vue-property-decorator";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import { HousfyDocumentCard } from "housfy-ui-lib";
import { IRentalLeasingFormatted } from "@/models/RentalLeasing";

@Component({
  name: "DocumentsPastLeasings",
  components: {
    HousfyLink,
    HousfyDocumentCard,
  },
})
export default class DocumentsPastLeasings extends Vue {
  @Prop({ type: String, required: true })
  propertyUuid!: string;
  @Prop({ type: Array, required: true })
  pastLeasings!: IRentalLeasingFormatted[];
}
