
import { Component, Prop, Vue } from "vue-property-decorator";
import { IExternalRouteHref, IExternalRoute, IRoute } from "@/models/IRoute";
import { resolveRoutePath } from "@/router";
import { AnchorTarget } from "@/models/Global";

@Component({})
export default class HousfyLink extends Vue {
  @Prop({ type: Object, required: true })
  to!: IRoute | IExternalRoute | IExternalRouteHref;

  @Prop({ type: String, required: true })
  tracking!: string;

  get target(): AnchorTarget | null {
    if (this.to.options?.shouldOpenNewWindow) return "_blank";
    return null;
  }
  get external(): boolean {
    return !!this.to.isExternal;
  }
  get path(): string {
    return resolveRoutePath(this.to);
  }
}
