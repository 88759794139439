var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"leasings flex flex-col gap-16 flex-grow py-32 bg-gray10"},[_c('p',[_vm._v(_vm._s(_vm.$t("common.pastLeasings")))]),_vm._l((_vm.pastLeasings),function(leasing){return _c('div',{key:leasing.leasingId},[_c('HousfyLink',{attrs:{"to":{
        name: 'documentsLeasing',
        params: {
          propertyUuid: _vm.propertyUuid,
          leasingId: leasing.leasingId,
        },
      },"tracking":`link_past-leasings_leasing-${leasing.leasingId}`,"data-qa":"documents-leasing-past"}},[_c('HousfyDocumentCard',{attrs:{"icon":"icons/folder","text":leasing.periodDate,"is-colored-version":""}})],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }